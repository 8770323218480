





import { defineComponent, PropType, computed } from '@nuxtjs/composition-api';
import { HomeComponentContent, SliderRenderStyles } from '~/features/home';

const componentMapper: Record<SliderRenderStyles, string> = {
  [SliderRenderStyles.ModelArtMainSlider]: 'HomeOffersModelArt1',
  [SliderRenderStyles.ModelArtSecondarySlider]: 'HomeOffersModelArt2',
  [SliderRenderStyles.ModelArtSecondarySliderGreen]: 'HomeOffersModelArt2',
  [SliderRenderStyles.BLOCKS_SLIDER]: 'HomeOffersBlockingSliderFragment',
  [SliderRenderStyles.VERTICAL_BLOCKS]: 'HomeOffersVerticalFragment',
};

export default defineComponent({
  props: {
    content: {
      type: [] as PropType<HomeComponentContent>,
      default: () => [],
    },
    componentStyle: {
      type: String as PropType<SliderRenderStyles>,
      default: 'HomeOffersFragment',
    },
  },
  setup(props) {
    return {
      item: computed(() => ({
        component: componentMapper[props.componentStyle],
        attributes:
          props.componentStyle === SliderRenderStyles.ModelArtSecondarySliderGreen
            ? {
                type: 'green',
              }
            : {},
      })),
    };
  },
});
