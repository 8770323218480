import { ComputedRef, computed, ref, useAsync, watch } from '@nuxtjs/composition-api';
import { useQuery } from 'villus';
import { useCachedSsrRef } from './serverCache';
import { useSetLocaleToCacheParams } from './i18n';
import { mapCarouselItems } from './Carousels';
import {
  FlashSaleDocument,
  HomeDocument,
  HomeQuery,
  HomeQueryVariables,
  RecentlyViewedDocument,
  BestSellersDocument,
} from '~/graphql/Home';
import { Unpacked } from '~/types/utils';
import { OfferCardFragment } from '~/graphql/fragments';

export enum HomeComponentType {
  'PRODUCTS_CAROUSEL',
  'RECENTLY_VIEWED_CAROUSEL',
  'BEST_SELLERS_CAROUSEL',
  'OFFERS',
  'FEATURED_CATEGORIES',
  'FEATURED_BRANDS',
  'ABOUT_BLOCKS',
}

export enum SliderRenderStyles {
  'ModelArtMainSlider' = 'MODEL_ART_MAIN_SLIDER',
  'ModelArtSecondarySlider' = 'MODEL_ART_SECONDARY_SLIDER',
  'ModelArtSecondarySliderGreen' = 'MODEL_ART_SECONDARY_GREEN_SLIDER',
  'BLOCKS_SLIDER' = 'BLOCKS_SLIDER', //
  'VERTICAL_BLOCKS' = 'VERTICAL_BLOCKS', //
}

export enum ProductRenderStyle {
  'DEFAULT',
  'INVERTED',
}

let PENDING = false;
const QUERY_THRESHOLD = 450;

export type CarouselContent = ReturnType<typeof mapCarouselItems>;
export type OffersContent = ReturnType<typeof mapOffersItems>;

export type HomeComponentContent = CarouselContent | OffersContent | null;

export type HomeComponent<T> = {
  type: HomeComponentType;
  componentStyle: SliderRenderStyles | ProductRenderStyle;
  content: T;
};

export type HomeItem = Unpacked<Unpacked<HomeQuery['homeItems']>['items']>;

export function useHomePage(variables?: ComputedRef<HomeQueryVariables>) {
  const { cacheParam } = useSetLocaleToCacheParams();
  const isFetchingPaginatedItems = ref(false);
  const isFetching = ref(false);

  const index = useCachedSsrRef<HomeComponent<HomeComponentContent>[] | undefined>(
    cacheParam('index'),
    undefined,
    /* ttl = 5 min  */ 60 * 1000 * 5
  );

  const indexCount = useCachedSsrRef<number | undefined>(cacheParam('index_count'), undefined);

  const { execute } = useQuery({
    query: HomeDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
    variables: variables?.value,
  });

  useAsync(async () => {
    // Don't refetch Home page  unless cache expired
    if (index.value && indexCount.value) {
      return;
    }
    if (PENDING) {
      let count = 0;

      // Wait until PENDING is false
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      await new Promise((resolve, reject) => {
        const interval = setInterval(() => {
          if (!PENDING) {
            // eslint-disable-next-line no-console
            console.log(
              '[Home Caching]: Result is fetched , Now Setting the Home to the one got from another process , ..continue'
            );

            clearInterval(interval);
            resolve(true);
          }

          if (count > QUERY_THRESHOLD) {
            // eslint-disable-next-line no-console
            console.log(
              '[Home Caching]: Timeout : Unable to get Home query from another process , resetting Home query and refetch again '
            );

            resolve(true);
            PENDING = false;
          } else {
            count++;
          }
        }, 100);
      });
    }

    // Don't refetch items unless cache expired
    if (index.value && indexCount.value) {
      return;
    }
    isFetching.value = true;

    const { data, error } = await execute();
    if (error) {
      isFetching.value = false;
      throw new Error(error?.message);
    }

    index.value = data?.homeItems.items.sort(sortItem).map(homeItemsMapper);
    indexCount.value = data?.homeItems?.page_info?.total_pages || 0;
    isFetching.value = false;
  });

  watch(
    () => variables?.value.page,
    async () => {
      try {
        isFetchingPaginatedItems.value = true;

        const { data } = await execute({
          variables: variables?.value,
        });

        if (data?.homeItems?.items?.length) {
          const result = data?.homeItems.items.sort(sortItem).map(homeItemsMapper);
          index.value = [...(index.value ? index.value : []), ...result];
          indexCount.value = data?.homeItems?.page_info?.total_pages || 0;
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      } finally {
        isFetchingPaginatedItems.value = false;
      }
    }
  );

  return { homeContent: index, isFetching, isFetchingPaginatedItems, totalPages: indexCount };
}

export function useRecentlyViewed() {
  const { data, isFetching } = useQuery({
    query: RecentlyViewedDocument,
    cachePolicy: 'network-only',
  });

  const recentlyViewedProducts = computed(() => mapCarouselItems(data.value?.carousel));

  return {
    recentlyViewedProducts,
    isFetching,
  };
}

export function useBestSellers() {
  const { cacheParam } = useSetLocaleToCacheParams();

  const bestSellers = useCachedSsrRef<CarouselContent | undefined>(
    cacheParam('best_sellers'),
    undefined,
    /* ttl = 24 hrs  */ 24 * 60 * 60 * 1000
  );

  const { execute, isFetching } = useQuery({
    query: BestSellersDocument,
    fetchOnMount: false,
    cachePolicy: 'network-only',
  });

  useAsync(async () => {
    if (bestSellers?.value) {
      return;
    }

    isFetching.value = true;
    const { data, error } = await execute();
    if (error) {
      isFetching.value = false;
      throw new Error(error?.message);
    }

    bestSellers.value = mapCarouselItems(data?.carousel);
    isFetching.value = false;
  });

  return {
    bestSellerProducts: bestSellers,
    isFetching,
  };
}

function sortItem(a: HomeItem | OfferCardFragment, b: HomeItem | OfferCardFragment) {
  return (a?.sort || Number.MAX_VALUE) - (b?.sort || Number.MAX_VALUE);
}

export function mapHomeOffer(offer: OfferCardFragment) {
  return {
    href: offer?.href,
    ...offer,
  };
}

function homeItemsMapper(item: HomeItem) {
  return {
    content: homeContentMapper(item),
    type: homeTypeMapper(item),
    componentStyle: renderStyleMapper(item),
  };
}

function homeContentMapper(item: HomeItem): HomeComponentContent {
  switch (item?.type) {
    case 'carousel':
      return mapCarouselItems(item.carousel);

    case 'banner':
      return mapOffersItems(item);

    default:
      return null;
  }
}

function homeTypeMapper(item: HomeItem): HomeComponentType {
  switch (item?.type) {
    case 'carousel':
      return item.carousel?.code === 'recently-viewed'
        ? HomeComponentType.RECENTLY_VIEWED_CAROUSEL
        : item.carousel?.code === 'best-sellers'
        ? HomeComponentType.BEST_SELLERS_CAROUSEL
        : HomeComponentType.PRODUCTS_CAROUSEL;

    case 'banner':
      return HomeComponentType.OFFERS;

    case 'featured-brands':
      return HomeComponentType.FEATURED_BRANDS;

    case 'about-blocks':
      return HomeComponentType.ABOUT_BLOCKS;
    default:
      return HomeComponentType.FEATURED_CATEGORIES;
  }
}

export const isValidOffer = (offer: any): offer is OfferCardFragment => {
  return !!offer?.id;
};

function mapOffersItems(item: HomeItem) {
  return (
    item?.offers
      ?.filter(isValidOffer)
      ?.map(offer => mapHomeOffer(offer))
      ?.sort(sortItem) || []
  );
}

function renderStyleMapper(item: HomeItem): SliderRenderStyles | ProductRenderStyle {
  if (item?.type === 'banner')
    switch (item?.style) {
      case 'model-art-1':
        return SliderRenderStyles.ModelArtMainSlider;
      case 'ModelArt2':
        return SliderRenderStyles.ModelArtSecondarySlider;
      case 'ArtModel2-green':
        return SliderRenderStyles.ModelArtSecondarySliderGreen;
      case 'vertical blocks':
        return SliderRenderStyles.VERTICAL_BLOCKS;

      default:
        return SliderRenderStyles.BLOCKS_SLIDER;
    }

  if (item?.type === 'carousel')
    switch (item?.style) {
      case 'inverted':
        return ProductRenderStyle.INVERTED;
      default:
        return ProductRenderStyle.DEFAULT;
    }

  return ProductRenderStyle.DEFAULT;
}

export function useFlashSale() {
  const { data } = useQuery({
    query: FlashSaleDocument,
    cachePolicy: 'network-only',
  });

  return {
    sale: computed(() => {
      if (!data.value?.flashsale_offers.length) {
        return null;
      }

      return {
        title: data.value?.flashsale_offers[0]?.title,
        startDate: data.value?.flashsale_offers[0]?.flash_sale_start_time_timezone_adjusted
          ? (data.value?.flashsale_offers[0].flash_sale_start_time_timezone_adjusted as string)
          : null,
        endDate: data.value?.flashsale_offers[0]?.flash_sale_end_time_timezone_adjusted as string,
      };
    }),
  };
}
